function FileIcon() {
  return (
      <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="41"
          fill="none"
          viewBox="0 0 40 41"
      >
        <path
            fill="#999"
            d="M29.623 36.364H16.197c-6.126 0-9.65-3.552-9.65-9.725V9.724C6.546 3.552 10.07 0 16.196 0h13.426c6.126 0 9.65 3.552 9.65 9.725v16.913c0 6.174-3.524 9.726-9.65 9.726zM16.197 2.537c-4.8 0-7.133 2.351-7.133 7.188v16.913c0 4.838 2.333 7.189 7.133 7.189h13.426c4.8 0 7.133-2.351 7.133-7.188V9.724c0-4.837-2.333-7.188-7.133-7.188H16.197z"
        ></path>
        <path
            fill="#999"
            d="M19.537 18.183H13.19c-.65 0-1.19-.495-1.19-1.091S12.54 16 13.19 16h6.347c.65 0 1.19.494 1.19 1.09 0 .597-.54 1.092-1.19 1.092zM17.182 15h-4.364c-.447 0-.818-.453-.818-1 0-.547.37-1 .818-1h4.364c.447 0 .818.453.818 1 0 .547-.37 1-.818 1zM21.21 21h-8.42c-.432 0-.79-.453-.79-1 0-.547.358-1 .79-1h8.42c.432 0 .79.453.79 1 0 .547-.358 1-.79 1z"
        ></path>
        <path
            stroke="#999"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M26 21l5-5M31 21l-5-5"
        ></path>

      </svg>
  );
}

export default FileIcon;
