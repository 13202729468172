// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader {
    width: 48px;
    height: 48px;
    border: 5px solid #EE7C4D;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
      0% {
          transform: rotate(0deg);
      }
      100% {
          transform: rotate(360deg);
      }
      } 
     `, "",{"version":3,"sources":["webpack://./src/components/Loader/loader.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,gCAAgC;IAChC,kBAAkB;IAClB,qBAAqB;IACrB,sBAAsB;IACtB,sCAAsC;IACtC;;IAEA;MACE;UACI,uBAAuB;MAC3B;MACA;UACI,yBAAyB;MAC7B;MACA","sourcesContent":[".loader {\n    width: 48px;\n    height: 48px;\n    border: 5px solid #EE7C4D;\n    border-bottom-color: transparent;\n    border-radius: 50%;\n    display: inline-block;\n    box-sizing: border-box;\n    animation: rotation 1s linear infinite;\n    }\n\n    @keyframes rotation {\n      0% {\n          transform: rotate(0deg);\n      }\n      100% {\n          transform: rotate(360deg);\n      }\n      } \n     "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
